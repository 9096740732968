import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { SlipsService } from '../services/slips.service';
import { SetpageService } from '../services/setpage.service';
import { EventEmitterService } from '../services/EventEmitterService';

@Component({
  selector: 'app-slips',
  templateUrl: './slips.component.html',
  styleUrls: ['./slips.component.scss']
})
export class SlipsComponent implements OnInit {

  private bsValue = new Date();
  private maxDate = new Date();
  private bsRangeValue: Date[];
  public selectedPage: number;
  private onlyLiq: boolean = false;
  public loadDownload: boolean = false;

  private listSlips = [];
  public bolCount: string;
  private filter: string = "";
  private showFilter: boolean = false;

  constructor(private appComponent: AppComponent, private slipsService: SlipsService, private setpageService: SetpageService) {
    this.listSlips = [];
    this.bolCount = "Boleto/Count";
    this.selectedPage = 1;
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }

  ngOnInit() {
    this.appComponent.checkMenu();
    this.loadSlips(10, 0);
  }

  private dateValue(data) {

    if (data == null)
      return '';

    var _data = new Date();
    if (!!data)
      _data = data;

    var date = new Date(_data.valueOf() - _data.getTimezoneOffset() * 60000);
    return date.toISOString().replace(/\.\d{3}Z$/, '');
  }

  private async loadSlips(numRegister: number, numPage: number) {
    let startDate = (this.showFilter == true ? (!!this.bsRangeValue ? this.dateValue(this.bsRangeValue[0]) : '') : '');
    let endDate = (this.showFilter == true ? (!!this.bsRangeValue ? this.dateValue(this.bsRangeValue[1]) : '') : '');
    let liq = (this.showFilter == true ? this.onlyLiq : false);

    let link = "Boleto/Count?numRegister=" + numRegister + "&numPage=" + numPage + "&filter=" + this.filter + "&settled=" + liq + "&dateStart=" + startDate + "&dateEnd=" + endDate;
    EventEmitterService.get('updateCount').emit({ "link": link });

    this.listSlips = await this.slipsService.getAll(numRegister, numPage, this.filter, liq, startDate, endDate);
    this.listSlips.forEach(item => {
      item.bolDatavencimento = new Date(item.bolDatavencimento);
      item.loadDownload = false;
    });
    console.log(this.listSlips);
  }

  private async sendSlip(slip: any) {
    slip.loadDownload = true;
    var data = await this.slipsService.DownloadSlip(slip.bolId);
    slip.loadDownload = false;
  }

  // PressEnter(event) {
  //   if (event.keyCode == 13 || event.which == 13) {
  //     this.loadSlips(10, this.selectedPage);
  //   }
  // }

  reciverPage(res) {
    if (res > 0) {
      let page = res - 1;
      this.selectedPage = res;
      this.setpageService.setItem("slips", res);
      this.loadSlips(10, page);
    }
  }

}
