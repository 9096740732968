import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BsDatepickerModule } from 'ngx-foundation';
import localePTCH from '@angular/common/locales/pt-CH';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePTCH);

import { defineLocale } from 'ngx-foundation/chronos';
import { ptBrLocale } from 'ngx-foundation/locale';
defineLocale('pt-br', ptBrLocale);

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MasterComponent } from './master/master.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { MainProductComponent } from './main-product/main-product.component';
import { CartComponent } from './cart/cart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './authorization.interceptor';
import { ConfigComponent } from './config/config.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConsultsComponent } from './consults/consults.component';
import { ModulesComponent } from './modules/modules.component';
import { SlipsComponent } from './slips/slips.component';
import { ConsultsCadComponent } from './consults-cad/consults-cad.component';
import { ModulesCadComponent } from './modules-cad/modules-cad.component';
import { PaginationComponent } from './components/Pagination/pagination.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdatesComponent } from './updates/updates.component';

import { ConsultsService } from './services/consults.service';
import { ModulesService } from './services/modules.service';
import { PaginationService } from './components/Pagination/pagination.service';
import { IntegradorStatusService } from './services/integradorStatus.service';
import { CompanyComponent } from './company/company.component';
import { CompanyListComponent } from './company-list/company-list.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MasterComponent,
    HomeComponent,
    MainProductComponent,
    CartComponent,
    ConfigComponent,
    ChangePasswordComponent,
    ConsultsComponent,
    ModulesComponent,
    SlipsComponent,
    ConsultsCadComponent,
    ModulesCadComponent,
    PaginationComponent,
    UpdatesComponent,
    CompanyComponent,
    CompanyListComponent
  ],
  imports: [
    HttpModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-ch' },
    ConsultsService,
    ModulesService,
    PaginationService,
    IntegradorStatusService,
  ],
  bootstrap: [MasterComponent]
})
export class AppModule {
  static returnbaseUrl() {
    return "https://gestormobileapi.abase.com.br/api/";
    //return "http://192.168.11.73:5000/api/";
    //return "http://192.168.11.171:5000/api/";
    // return "http://localhost:5000/api/";
  }

  static returnUrlSystem(){
    return "http://localhost:4200/";
  }
 }