import { Component, OnInit } from '@angular/core';
import { User } from '../entities/user';
import { LoginService } from '../services/login.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  public pass: string = "";
  public password: string = "";
  public rePassword: string = "";
  public verifyPass1: boolean = false;
  public verifyPass2: boolean = false;
  public verifyPass3: boolean = false;
  public verifyPass4: boolean = false;
  public verifyPass5: boolean = false;
  public hashRecover: string = "";
  public user: User;

  private descriptionChangePass: string = "Salvar";
  private statusChangePass: boolean = false;

  // private userCode: any;
  // private AdminOrUser: number;

  // private pass: string;
  // private newPass: string;
  // private repeatNewPass: string;

  // private Required: boolean = false;
  // private changePass1: boolean = false;
  // private changePass2: boolean = false;
  // private changePass3: boolean = false;
  // private changePass4: boolean = false;

  constructor(private loginService: LoginService, private router: Router) {
    this.user = new User();
  }

  ngOnInit() {
    // setTimeout(() => $(document).foundation());
    // this.userCode = JSON.parse(localStorage.getItem("User"));

    // //TypeUser 1 = Admin; 2 = User;
    // this.AdminOrUser = this.userCode.typeUser;

    // this.serviceClient.SelectClient(this.userCode.code).then((result) => {
    //   if (result != null) {
    //     this.clientLogged = result;
    //     this.clientLogged.typeUser = this.userCode.typeUser;
    //     this.userData.User = this.clientLogged;
    //   }
    // });
  }

  Verifications() {
    if (this.password != "") {
      this.verifyPass1 = false;

      if (this.rePassword != "") {
        this.verifyPass2 = false;

        if (this.password == this.rePassword) {
          this.verifyPass3 = false;

          var teste = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[§'+º´~,.!#$%&/()=?*ª`^|;:_@€£‰¶÷≠]).*$/;
          var teste2 = teste.test(this.password);
          if (teste2 != null && teste2 != false) {
            this.verifyPass4 = false;

            this.user.Logged = true;
            this.user.use_spassword = this.password;
            this.user.Hash = this.hashRecover;

            this.loginService.VerifyChangePass(this.user).then((result) => {
              if (result == 1) {
                swal.fire({
                  title: "Sucesso! :)",
                  text: "Suas alterações foram salvas",
                  type: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.value) {
                    this.router.navigate(['/login']);
                  }
                });
              } else if (result == 2) {
                swal.fire("Oops! :(", "Seu pedido de recuperação de senha expirou, faça um novo pedido para recuperar a senha.", "error");
              } else if (result == 3) {
                swal.fire("Oops! :(", "Usuário não encontrado.", "error");
              } else {
                swal.fire("Oops! :(", "Infelizmente não foi possível salvar suas alterações no momento, tente novamente mais tarde.", "error");
              }
            });

          } else {
            this.verifyPass4 = true;
          }
        } else {
          this.verifyPass3 = true;
        }
      } else {
        this.verifyPass2 = true;
      }
    } else {
      this.verifyPass1 = true;
    }
  }

  // if (this.clientLogged.typeUser == 2) {
  //   if (!!this.clientLogged.name && !!this.clientLogged.neighborhood && !!this.clientLogged.shippingAddress && !!this.clientLogged.uf && !!this.clientLogged.cep && !!this.clientLogged.city) {
  //     this.Required = false;
  //   } else {
  //     this.Required = true;
  //   }
  // }

  // if (!!this.pass && !!this.newPass && !!this.repeatNewPass) {
  //   this.changePass2 = false;
  //   if (!!this.pass && this.newPass == this.repeatNewPass) {
  //     this.changePass1 = false;
  //   } else {
  //     this.changePass1 = true;
  //   }
  // } else if (!!!this.pass && !!!this.newPass && !!!this.repeatNewPass) {
  //   this.changePass2 = false;
  // } else {
  //   this.changePass2 = true;
  // }

  // this.SaveConfig();
  // }

  // SaveConfig() {
  //   var teste = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[§'+º´~,.!#$%&/()=?*ª`^|;:_@€£‰¶÷≠]).*$/;
  //   var teste2 = teste.test(this.newPass);
  //   if (teste2 != null && teste2 != false) {
  //     this.changePass4 = false;
  //     if (this.changePass1 == false && this.changePass2 == false && !!this.pass && !!this.newPass && !!this.repeatNewPass) {
  //       // this.userData.Pass = this.pass;
  //       // this.userData.NewPass = this.newPass;
  //       this.changePass3 = false;
  //       // this.serviceLogin.ChangePass(this.userData).then((result) => {
  //       //   if (result == 1) {
  //       //     this.changePass3 = false;
  //       //     swal({
  //       //       title: "Sucesso! :)",
  //       //       text: "Suas alterações foram salvas",
  //       //       type: "success",
  //       //       confirmButtonText: "Ok",
  //       //     }).then((result) => {
  //       //       if (result.value) {
  //       //         this.router.navigate(['mn/home']);
  //       //         return true;
  //       //       }
  //       //     });
  //       //   } else if (result == 2) {
  //       //     this.changePass3 = true;
  //       //   } else {
  //       //     swal("Oops! :(", "Infelizmente não foi possível salvar suas alterações no momento, tente novamente mais tarde.", "error");
  //       //   }
  //       // });
  //     }
  //   } else {
  //     this.changePass4 = true;
  //   }
  // }

}
