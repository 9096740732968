import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class SetpageService {

  constructor(private localStorage: LocalStorage) { }

  public setItem(key, obj) {
    return localStorage.setItem(key, JSON.stringify(obj))
  }

  public getItem(key) {
    return JSON.parse(localStorage.getItem(key))
  }
}
