import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private baseUrl = AppModule.returnbaseUrl();
  private returnUrlSystem = AppModule.returnUrlSystem();

  constructor(private http: HttpClient) { }

  // public validUser(user: string, password: string): any {
  //   var url = this.baseUrl + "Usuario/Authenticate";
  //   return this.http.post(url,
  //     {
  //       "usu_semail": user,
  //       "usu_spassword": password
  //     })
  //     .toPromise()
  //     .then(
  //       res => { // Success
  //         return res;
  //       },
  //       msg => { // Error
  //         return msg;
  //       });
  // }

  public validUser(user: string, password: string): any {
    var url = this.baseUrl + "Usuario/Authtoken";
    return this.http.post(url,
      {
        "usu_semail": user,
        "usu_spassword": password,
        "emp_id": 0,
        "loc_id": 0
      })
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public ChangePassword(_object) {
    var url = this.baseUrl + "Usuario/ChangePassword";
    return this.http.post(url, _object, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public VerifyChangePass(_object) {
    var url = this.baseUrl + "Usuario/VerifyChangePass";
    return this.http.post(url, _object, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public SendEmail(email) {
    var url = this.baseUrl + "Usuario/SendEmail";
    return this.http.post(url,
      {
        "email": email,
        "baseUrl": this.returnUrlSystem,
      })
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

}