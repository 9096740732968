import { Component, OnInit } from '@angular/core';
import { ModulesService } from '../services/modules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Modules } from '../entities/modules';
import { User } from '../entities/user';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private modules: Modules[];
  private verifySelected: boolean = false;
  private moduleSelected: Modules;
  private verifyHome: boolean = false;
  private user: User;

  constructor(private router: Router, private modulesService: ModulesService, private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent) {
    this.modules = [];
    this.moduleSelected = new Modules;
    this.user = new User();
  }

  ngOnInit() {
    var userTemp = JSON.parse(localStorage.getItem("User"));
    this.user.per_id = userTemp.perId;
    this.goTo("consults");
  }

  selectedModule(mod) {
    this.verifySelected = true;
    this.moduleSelected = mod;
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
    this.verifyHome = true;
    this.appComponent.checkMenu();
  }
}
