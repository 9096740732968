import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultsService } from '../services/consults.service';
import { SetpageService } from '../services/setpage.service';
import { ModulesService } from '../services/modules.service';
import { EventEmitterService } from '../services/EventEmitterService';

@Component({
  selector: 'app-consults',
  templateUrl: './consults.component.html',
  styleUrls: ['./consults.component.scss']
})
export class ConsultsComponent implements OnInit {

  public linkCount: string;
  public consults: [];
  public selectedPage: number;
  public moduleId: number;
  public modules: [];

  constructor(private router: Router, private consultsService: ConsultsService, private setpageService: SetpageService, 
    private modulesService: ModulesService) {
    this.moduleId = 0;
    this.linkCount = "Consulta/Count?modId=" + this.moduleId;
    this.consults = [];
    this.modules = [];
    this.selectedPage = 1;
  }


  ngOnInit() {
    let index = this.setpageService.getItem("consults");
    if (!!index) {
      this.selectedPage = index;
      this.loadData(10, parseInt(index) - 1);
    }
    else
      this.loadData(10, 0);


    this.loadModules();
  }

  getForModules() {
    this.loadData(10, 0);
    let link = "Consulta/Count?modId=" + this.moduleId;
    EventEmitterService.get('updateCount').emit({ "link": link });
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
  }

  async loadModules() {
    this.modules = await this.modulesService.getAll(true);
  }

  loadData(numRegister: number, numPage: number) {
    this.consults = [];
    this.consultsService.getAll(numRegister, numPage, this.moduleId).then((result) => {
      if (result.length > 0) {
        if(result.conEnviaImagen == 1){
          result.conEnviaImagen = true;
        } else {
          result.conEnviaImagen = false;
        }
        this.consults = result;
      }
    });
  }

  reciverPage(res) {
    if (res > 0) {
      let page = res - 1;
      this.selectedPage = res;
      this.setpageService.setItem("consults", res);
      this.loadData(10, page);
    }
  }


}
