import { extend } from "webdriver-js-extender"; 

export class User {
    public use_id: number;
    public use_sname: string;
    public use_semail: string;
    public use_spassword: string;
    public use_ssalt: string;
    public use_bactive: boolean;
    public use_ddataregister: Date;
    public use_ddateupdate: Date;
    public confirmPassword: string;
    public token: any;
    public com_id: number;
    public loc_id: number;
    public per_id: number;

    public Pass: string;
    public NewPass: string;
    public lvlPass: boolean;
    public Logged: boolean;
    public Hash: string;
}