import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  public filter: string = "";
  public companies: Array<any> = [];

  constructor(private companyService: CompanyService, private router: Router,  private appComponent: AppComponent) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.companies = await this.companyService.getAll(this.filter);
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
    this.appComponent.checkMenu();
  }

}
