import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyService } from '../services/company.service';
import swal from 'sweetalert2';
import { ModulesService } from '../services/modules.service';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  public form: FormGroup;
  public valid: boolean = false;
  public modules: [];

  constructor(private router: Router, private modulesService: ModulesService, private formBuilder: FormBuilder, private companyService: CompanyService) {
    this.createBuilder();
  }

  ngOnInit() {
    this.listModules();
  }

  createBuilder() {
    this.form = this.formBuilder.group({
      empDescricao: ['', [Validators.required]],
      locCNPJ: ['', [Validators.required]],
      locCode: ['', [Validators.required]],
      modId: ['', [Validators.required]],
      replicacao: [false, []],
    });
  }

  get ctrl() { return this.form.controls; }

  public async save() {
    this.valid = true;
    if (this.form.valid) {
      let data = {
        company: [{
          EmpDescricao: this.ctrl.empDescricao.value,
          EmpAtivo: true,
          EmpChaveempresa: ''
        }],
        locCode: this.ctrl.locCode.value,
        locCNPJ: this.ctrl.locCNPJ.value,
        modId: this.ctrl.modId.value,
        replicacao: this.ctrl.replicacao.value
      }
      let res = await this.companyService.save(data);
      if (res) {
        swal.fire("Sucesso!", "Salvo com sucesso!", "success").then((res) => {
          this.goTo('company-list');

        });
      } else {
        swal.fire("Opss!", "Falhou! error: " + res, "error");
      }
    }
  }

  cancel() {
    this.form.reset();
    this.goTo('company-list');
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
  }

  private async listModules() {
    this.modules = await this.modulesService.getAll(true);
  }

}
