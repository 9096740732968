import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModulesService } from '../services/modules.service';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../services/EventEmitterService';
import { Modules } from '../entities/modules';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {

  private modules: Modules;
  public selectedPage: number;
  public filter: string;
  public linkCount: string;


  constructor(private router: Router, private modulesService: ModulesService, private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent) {
      this.selectedPage = 1;
      // this.linkCount = "Modulo/Count?active=true";
      this.filter = "";
      this.modules = new Modules();
     }

  ngOnInit() {
    this.checkModules();
    this.appComponent.checkMenu();
  }

  private async checkModules() {
    this.modules = await this.modulesService.getAll(false);
    console.log(this.modules);
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
    this.appComponent.checkMenu();
  }

  async search(){
    this.modules = await this.modulesService.getAll(false, this.filter);
    // let link = "Modulo/Count?active=true&filter=" + this.filter;
    // EventEmitterService.get('updateCount').emit({ "link": link });
  }

}
