import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PaginationService } from './pagination.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { EventEmitterService } from 'src/app/services/EventEmitterService';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {

  @Input() linkCount: string;
  @Input() range: number;
  @Output() responsePage = new EventEmitter();
  @Input() selectedPage: number;

  private totalItens: number;
  private rangePagination: number;
  private numberOfPages: number;
  private arraryNumbers: number[];
  private cont: number;
  private _numMax: number;
  private _numMin: number;
  private testLoadPage: boolean = true;
  private lengthList: number;
  private sub: any;
  constructor(private tableService: PaginationService, private localStorage: LocalStorage) {
  }

  ngOnInit() {
    this.counData(this.linkCount);

    this.sub = EventEmitterService.get('updateCount').subscribe((data) => {
      this.counData(data.link);
      this.selectedPage = 1;
      this._numMin = 1;
    });
  }

  async counData(link: string) {
    this.lengthList = 0;
    this.lengthList = await this.tableService.getData(link);
    if (this.lengthList > 0)
      this.calculatePage();
  }

  calculatePage() {
    this.numberOfPages = this.lengthList / this.range;

    if (this.numberOfPages % 2 != 0) {
      this.numberOfPages++;
      this.numberOfPages = parseInt(this.numberOfPages.toString());
    }

    var lentghPage = 0;
    if (this.testLoadPage) {
      var lentghPage = 0;
      if (this.numberOfPages > 9) {
        lentghPage = 9;
      } else {
        lentghPage = this.numberOfPages;
      }
      this.changePagination(lentghPage, 1);
      this.testLoadPage = false;
    } else {
      this.changePagination(this.numberOfPages, this._numMin);
    }
  }

  changePagination(numMax, numMin): void {
    if (numMax > 0) {
      this.arraryNumbers = [];
      for (var i = numMin; i <= numMax; i++) {
        this.cont++;
        this.arraryNumbers.push(i);
      }
    }
  }

  selectPage(numPage): void {
    this._numMax = 0
    this._numMin = 0
    if (!!numPage) {
      this.selectedPage = numPage;
      this.responsePage.emit(numPage);
      localStorage.setItem('indexPage', numPage);
      if (numPage > 5) {
        this._numMax = numPage + 4;
        if (this._numMax > this.numberOfPages) {
          this._numMax = this.numberOfPages;
        }
        this._numMin = numPage - 4;
        this.testLoadPage = false;
        this.changePagination(this._numMax, this._numMin);
      } else {
        var lentghPage = 0;
        if (this.numberOfPages > 9) {
          lentghPage = 9;
        } else {
          lentghPage = this.numberOfPages;
        }
        this.changePagination(lentghPage, 1);
      }

    }
  }

  backPage() {
    var _backPage = this.selectedPage - 1;
    if (_backPage > 0) {
      this.selectedPage = _backPage;
      this.selectPage(_backPage);
    }
  }

  nextPage() {
    var _nextPage = this.selectedPage + 1;
    if (_nextPage <= this.numberOfPages) {
      this.selectedPage = _nextPage;
      this.selectPage(_nextPage);
    }
  }


}
