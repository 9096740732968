import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from '../app.module';
import { saveAs } from 'file-saver';
import { RequestOptions, ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class SlipsService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }

  getAll(numRegister: number, numPage: number, filter: string, settled: boolean, initDate: string, endDate: string) {
    var url = this.baseUrl + "Boleto/GetAll?numRegister=" + numRegister + "&numPage=" + numPage + "&filter=" + filter + "&settled=" + settled + "&dateStart=" + initDate + "&dateEnd=" + endDate;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  DownloadSlip(codeSlip: number) {
    var url = this.baseUrl + "Boleto/DownloadSlip?codeSlip=" + codeSlip;
    const options = new RequestOptions({ responseType: ResponseContentType.Blob });
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          const byteArray = new Uint8Array(atob(res.toString()).split('').map(char => char.charCodeAt(0)));
          this.saveFile(byteArray, "Boleto"+codeSlip);
          return true;
        },
        msg => { // Error
          return msg;
        });
  }

  saveFile(byteArray, fileName: string) {
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    saveAs(blob, fileName);
  };

}
