import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppModule } from '../app.module';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }


  save(_object) {
    var url = this.baseUrl + "Empresa/RegistreCompany";
    return this.http.post(url, _object, {})
      .toPromise()
      .then(
        res => { // Success
          const byteArray = new Uint8Array(atob(res.toString()).split('').map(char => char.charCodeAt(0)));
          this.saveFile(byteArray, "ConfigMobile");
          return true;
        },
        msg => { // Error
          return false;
        });

  }
  saveFile(byteArray, fileName: string) {
    var blob = new Blob([byteArray], { type: 'application/xml' });
    saveAs(blob, fileName);
  };

  getAll(filter: string = "") {
    var url =  `${this.baseUrl}Empresa/GetAll?filter=${filter}`;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }
}
