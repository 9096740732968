import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }

  getAll(active, filter:string = "") {
    var url = this.baseUrl + "Modulo/GetAll?active="+active+"&filter="+filter;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  getByUser(active: boolean) {
    var url = this.baseUrl + "Modulo/GetByUserAll?active=" + active;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  getById(modId: number) {
    var url = this.baseUrl + "Modulo/GetById?modId=" + modId;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  save(_object) {
    var url = this.baseUrl + "Modulo/Save";
    return this.http.post(url, _object, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

}
