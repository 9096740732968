import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainProductComponent } from './main-product/main-product.component';
import { ConfigComponent } from './config/config.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConsultsComponent } from './consults/consults.component';
import { ModulesComponent } from './modules/modules.component';
import { SlipsComponent } from './slips/slips.component';
import { ConsultsCadComponent } from './consults-cad/consults-cad.component';
import { ModulesCadComponent } from './modules-cad/modules-cad.component';
import { UpdatesComponent } from './updates/updates.component';
import { CompanyComponent } from './company/company.component';
import { CompanyListComponent } from './company-list/company-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [{
      path: 'login',
      component: LoginComponent
    }]
  },
  {
    path: '', component: AppComponent,
    children: [
      {
        path: "home", component: HomeComponent,
        children: [
          { path: "consults", component: ConsultsComponent },
          { path: "consults-cad", component: ConsultsCadComponent },
          { path: "consults-cad/:codConsult", component: ConsultsCadComponent },
          { path: "modules", component: ModulesComponent },
          { path: "modules-cad", component: ModulesCadComponent },
          { path: "modules-cad/:codModule", component: ModulesCadComponent },
          { path: "slips", component: SlipsComponent },
          { path: "updates", component: UpdatesComponent },
          { path: "company", component: CompanyComponent },
          { path: "company-list", component: CompanyListComponent },
        ]
      },
      { path: "product/:codProduto", component: MainProductComponent },
      { path: "config", component: ConfigComponent },
    ]
  },
  {
    path: '',
    children: [{
      path: 'changepass/:hash',
      component: ChangePasswordComponent
    }]
  }
  //,
  // { path: "changepass/:hash", component: ChangePasswordComponent },
  // { path: 'login', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
