import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { User } from '../entities/user';
import swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public password: string = "";
  public rePassword: string = "";
  public verifyPass1: boolean = false;
  public verifyPass2: boolean = false;
  public verifyPass3: boolean = false;
  public verifyPass4: boolean = false;
  public hashRecover: string = "";
  public user: User;

  private descriptionChangePass: string = "Salvar";
  private statusChangePass: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginService: LoginService) { 
    this.user = new User();
  }

  ngOnInit() {
    this.hashRecover = this.activatedRoute.snapshot.params['hash'];
  }

  Change(){
    this.descriptionChangePass = "Aguarde";
    this.statusChangePass = true;
    if(this.password != ""){
      this.verifyPass1 = false;

      if(this.rePassword != ""){
        this.verifyPass2 = false;
  
        if(this.password == this.rePassword){
          this.verifyPass3 = false;

          var teste = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[§'+º´~,.!#$%&/()=?*ª`^|;:_@€£‰¶÷≠]).*$/;
          var teste2 = teste.test(this.password);
          if (teste2 != null && teste2 != false) {
            this.verifyPass4 = false;

            this.user.Logged = false;
            this.user.use_spassword = this.password;
            this.user.Hash = this.hashRecover;

            this.loginService.VerifyChangePass(this.user).then((result) => {
              if (result == 1) {
                swal.fire({
                  title: "Sucesso! :)",
                  text: "Suas alterações foram salvas",
                  type: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.value) {
                    this.router.navigate(['/login']);
                  }
                });
              } else if(result == 2){
                swal.fire("Oops! :(", "Seu pedido de recuperação de senha expirou, faça um novo pedido para recuperar a senha.", "error");
              } else if(result == 3){
                swal.fire("Oops! :(", "Usuário não encontrado.", "error");
              } else {
                swal.fire("Oops! :(", "Infelizmente não foi possível salvar suas alterações no momento, tente novamente mais tarde.", "error");
              }
            });

          } else {
            this.verifyPass4 = true;
          }
        } else {
          this.verifyPass3 = true;
        }
      }else{
        this.verifyPass2 = true;
      }
    }else{
      this.verifyPass1 = true;
    }
    this.descriptionChangePass = "Salvar";
    this.statusChangePass = false;
  }

  GoToLogin(){
    this.router.navigate(['/login']);
  }

}
