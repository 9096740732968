import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.indexOf('/api/Usuario/Authenticate') == -1
            && request.url.indexOf('https://viacep.com.br/ws/') == -1
            && request.url.indexOf('/api/Usuario/Authtoken') == -1
            && request.url.indexOf('/api/Usuario/SendEmail') == -1
            && request.url.indexOf('/api/Usuario/VerifyChangePass') == -1) {

            const data = JSON.parse(localStorage.getItem("User"));
            if (data == null)
                this.router.navigate(['/login']);

            const token = data.token.access_token;
            if (token == null)
                this.router.navigate(['/login']);


            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 403) {
                   // this.router.navigate(['/login']);
                }

                if (error.status == 404) {
                    //this.router.navigate(['/login']);
                }

                if (error.status == 500) {
                   // this.router.navigate(['/login']);
                }
                if (error.status == 401) {
                    this.router.navigate(['/login']);
                }
                console.log("Status: " + error.status);
                console.error("Error request ", error);
                return throwError(error);
            })
        );
    }
}