import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModulesService } from '../services/modules.service';
import { ConsultsService } from '../services/consults.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-consults-cad',
  templateUrl: './consults-cad.component.html',
  styleUrls: ['./consults-cad.component.scss']
})
export class ConsultsCadComponent implements OnInit {

  private modules: [];
  private module: any;
  private consulta: any;

  constructor(private router: Router, private modulesService: ModulesService, private consultsService: ConsultsService, private activatedRoute: ActivatedRoute) {
    this.modules = [];
    this.consulta = new Object();
    this.module = {};
    this.consulta.conSequencia = 0;
    this.consulta.conConsulta = "";
    this.consulta.conDescricao = "";
    this.consulta.conCondicaoData = "";
    this.consulta.conCondicaoFinal = "";
    this.consulta.conUrl = "";
    this.consulta.conAtivo = true;
    this.consulta.modId = 0;
    this.consulta.conEnviaImagen = true;
    this.consulta.conExecutanodia = true;
    this.consulta.conExecutaUmaVez = false;
  }

  ngOnInit() {
    this.listModules();
    this.consulta.conId = this.activatedRoute.snapshot.params['codConsult'];
    if (this.consulta.conId > 0)
      this.getConsulta();
    else {
      this.consulta.conId = 0;
    }
  }

  private async getConsulta() {
    this.consulta = await this.consultsService.getById(this.consulta.conId, this.consulta.modId);
    this.consulta.conEnviaImagen = this.consulta.conEnviaImagen == 1 ? true : false;
    this.consulta.conExecutanodia = this.consulta.conExecutanodia == 1 ? true : false;
    this.consulta.conExecutaUmaVez = this.consulta.conExecutaUmaVez == 1 ? true : false;
  }

  private async listModules() {
    this.modules = await this.modulesService.getAll(true);
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
  }

  private save() {
    let consulta = this.consulta;

    let message = "";


    if (!!!this.consulta.conConsulta)
      message = "A consulta deve ser informada!"

    if (this.consulta.conSequencia == 0)
      message = "Informe uma sequência para consulta!";

    if (!!!this.consulta.conDescricao)
      message = "Informe a descrição da consulta!";

    // if (!!!this.consulta.conUrl)
    //   message = "Informe a url da consulta!";

    if (this.consulta.modId == 0)
      message = "Selecione o modulo!";

    if (message != "") {
      swal.fire("Opss!", message, "warning");
      return;
    }

    if (this.consulta.conEnviaImagen == true) {
      consulta.conEnviaImagen = 1;
    } else {
      consulta.conEnviaImagen = 0;
    }

    if (this.consulta.conExecutanodia == true) {
      this.consulta.conExecutanodia = 1;
    } else {
      this.consulta.conExecutanodia = 0;
    }

    this.consulta.conExecutaUmaVez = this.consulta.conExecutaUmaVez ? 1 : 0;

    this.consultsService.Save(consulta).then((res) => {
      if (res === true) {
        swal.fire("Sucesso!", "Salvo com sucesso!", "success").then((res) => {
          this.goTo('consults');
        });
      } else {
        swal.fire("Opss!", "Falhou! error: " + res.message, "error");
      }
    });
  }
}
