import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModulesService } from '../services/modules.service';
import { Modules } from '../entities/modules';
import Swal from 'sweetalert2';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-modules-cad',
  templateUrl: './modules-cad.component.html',
  styleUrls: ['./modules-cad.component.scss']
})
export class ModulesCadComponent implements OnInit {

  private codModule: number;
  private modules: Modules;
  private base64textString: string = "";
  private modBaixaPedidoB: boolean = true;
  private modBaixaCliente: boolean;
  private modBaixaApiExt: boolean;

  constructor(private router: Router, private modulesService: ModulesService, private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent) {
    this.modules = new Modules();
  }

  ngOnInit() {
    this.codModule = this.activatedRoute.snapshot.params['codModule'];
    this.appComponent.checkMenu();

    if (this.codModule > 0) {
      this.loadModule(this.codModule);
    } else {
      this.modules.modAtivo = true;
    }
  }

  private async loadModule(codMod) {
    this.modules = await this.modulesService.getById(codMod);
    if (this.modules.modBaixaPedido == 1) {
      this.modBaixaPedidoB = true;
    } else {
      this.modBaixaPedidoB = false;
    }

    this.modBaixaCliente = this.modules.modBaixaCliente == 0 ? false : true;

    this.base64textString = this.modules.modImage;

    this.modBaixaApiExt = this.modules.modBaixaApiExterna == 1;
  }

  private async save() {
    if (this.modules.modDescricao == "") {
      return 1;
    }

    this.modules.modBaixaPedido = this.modBaixaPedidoB == true ? 1 : 0;
    this.modules.modBaixaCliente = this.modBaixaCliente == true ? 1 : 0;
    this.modules.modBaixaApiExterna = this.modBaixaApiExt == true ? 1 : 0;

    let result = await this.modulesService.save(this.modules);
    if (result == true) {
      Swal.fire({
        title: "Sucesso! :)",
        text: "Suas alterações foram salvas",
        type: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          this.goTo("modules");
        }
      });
    } else {
      Swal.fire("Falhou!", "Não foi possível salvar o modulo!" + JSON.stringify(result), "error");
    }

  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
    this.appComponent.checkMenu();
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.modules.modImage = btoa(binaryString);
  }
}