import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class IntegradorStatusService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }

  public getAll() {
    var url = this.baseUrl + "IntegradorStatus/GetAll";
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public UpdateIntegrator(codeBusiness: number, codeLocal: number) {
    var url = this.baseUrl + "IntegradorStatus/UpdateIntegrator?codeBusiness=" + codeBusiness + "&codeLocal=" + codeLocal;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

}
