import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private email: string;
  private password: string;
  private isValid: boolean;
  private inValid: boolean;
  private ForgotPassword: boolean = false;
  private isValidEmail: boolean;
  private isValidUserRecover: boolean;
  private inValidEmail: boolean;
  private emailUser: string;
  private userRecover: string;
  private sendingEmail: boolean = false;

  private descriptionButton: string;
  private descriptionChangePass: string = "Enviar";
  private statusChangePass: boolean = false;
  private statusButton: boolean;

  private idClient: string;
  private hashRecover: string;
  private recoverPassword: boolean;
  private newPassword: string;
  private confirmPassword: string;
  public lvlPass: any;

  private verify: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginService: LoginService,
    private localStorage: LocalStorage) {
    this.verify = false;
    this.email = ""; 
    this.password = ""; 
    this.descriptionButton = "Entrar";


    this.statusButton = false;
    this.isValid = true;
    this.inValid = false;
    this.ForgotPassword = false;
    this.isValidEmail = false;
    this.isValidUserRecover = false;
    this.emailUser = "";
    this.userRecover = "";
    this.idClient = "";
    this.hashRecover = "";
    this.recoverPassword = false;
    this.newPassword = "";
    this.confirmPassword = "";
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById("user").focus();
    });
  }

  ExecuteLogin() {
    if (this.email != "" && this.password != "") {
      this.isValid = true;
    } else {
      swal.fire('Oops!', 'Usuário ou senha devem serem informados!', 'warning');
      this.isValid = false;
    }
    if (this.isValid) {
      this.startLoader();
      this.loginService.validUser(this.email, this.password).then((result) => {
        this.stopLoader();
        if (result.usu_id > 0) {
          this.verify = true;
          var teste = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[§'+º´~,.!#$%&/()=?*ª`^|;:_@€£‰¶÷≠]).*$/;
          result.lvlPass = teste.test(this.password);
          result.Logged = true;
          localStorage.setItem('User', JSON.stringify(result));
          this.router.navigate(['/home']);
        } else {
          swal.fire('Oops!', 'Usuário ou senha inválidos!', 'error');
          this.password = "";
        }
        // if (result.authenticated) {
        // } else {
        //   this.inValid = false;
        //   localStorage.setItem('User', null);
        //}
      });
    }
  }

  SendEmail() {
    this.descriptionChangePass = "Aguarde...";
    this.statusChangePass = true;
    if (this.emailUser == "") {
      this.isValidEmail = true;
    } else {
      this.loginService.SendEmail(this.emailUser).then((result) => {
        if (result == true) {
          this.descriptionChangePass = "Enviar";
          this.statusChangePass = false;
          this.sendingEmail = false;
          swal.fire({
            title: "Sucesso! :)",
            text: "Acesse seu e-mail para mais informações referente a recuperação de sua senha.",
            type: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              this.email = "";
              this.password = "";
              this.emailUser = "";
              this.ForgotPassword = false;
            }
          });
          // swal.fire("Sucesso!", "Acesse seu e-mail para mais informações referente a recuperação de sua senha.", "success");
        } else {
          this.sendingEmail = false;
          this.descriptionChangePass = "Enviar";
          this.statusChangePass = true;
          swal.fire("Opss!", "Não foi possível solicitar a recuperação de sua senha, por favor verifique o e-mail informado!", "error");
        }
      });
    }
  }

  startLoader() {
    this.descriptionButton = "Aguarde...";
    this.statusButton = true;
  }

  stopLoader() {
    this.descriptionButton = "Entrar";
    this.statusButton = false;
  }

  PressEnterUser() {
    document.getElementById('password').focus();
  }

  PressEnter(event) {
    if (event.keyCode == 13 || event.which == 13) {
      this.ExecuteLogin();
    }
  }

}
