import { Component, OnInit } from '@angular/core';
import { IntegradorStatusService } from '../services/integradorStatus.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss']
})
export class UpdatesComponent implements OnInit {

  private business: [];

  constructor(private integradorStatusService: IntegradorStatusService) {
    this.business = [];
   }

  ngOnInit() {
    this.getAllStatusService();
  }

  async getAllStatusService(){
    this.business = await this.integradorStatusService.getAll();
    console.log("Lista de usuarios ", this.business);
  }

  async attBusiness(Business){
    if(Business.usu_online == 1){
      swal.fire({
        title: "Atenção!",
        text: "Deseja atualizar o Integrador deste cliente?",
        type: "warning",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.integradorStatusService.UpdateIntegrator(Business.integradorStatus.emp.empId, Business.loc_id);
        }
      });
    } else {
      swal.fire("Oops! :(", "O Atualizador está indisponível ou o computador do mesmo está desligado", "error");
    }
  }

}
