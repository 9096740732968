import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class ConsultsService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }

  public Save(data: any) {
    let url = this.baseUrl + "Consulta/Save";
    return this.http.post(url, data, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public getById(idConsulta: number, idModule: number) {
    var url = this.baseUrl + "Consulta/GetById?codeConsulta=" + idConsulta + "&codeModule=" + idModule;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }

  public getAll(numRegister: number, numPage: number, modId: number) {
    var url = this.baseUrl + "Consulta/GetAll?numRegister=" + numRegister + "&numPage=" + numPage+"&modId="+modId;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }
}
