import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import swal from 'sweetalert2';

import { User } from './entities/user';
import { UserData } from './entities/userdata';
import { LoginService } from './services/login.service';
// import { FormatTitle } from './helpers/formatTitle';
import { HomeComponent } from './home/home.component';
import { BsLocaleService } from 'ngx-foundation';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private checkMenuSlips: number = 0;

  public goToHome: boolean;
  private ClientInfo: any;
  private changePass1: boolean = false;
  private changePass2: boolean = false;
  private changePass3: boolean = false;
  private changePass4: boolean = false;
  public changePass5: any;
  private pass: string;
  private newPass: string;
  private repeatNewPass: string;
  private userData: UserData;
  private user: User;
  private menuUser: [{}];
  private menuSuporte: [{}];
  private menuAdminEmp: [{}];
  private menuDesenvolvimento: [{}];
  private menuUserIntegracao: [{}];
  private menuToShow: [{}];

  constructor(private router: Router, private localStorage: LocalStorage, private loginService: LoginService,
    private cdRef: ChangeDetectorRef, private localeService: BsLocaleService) {
    localeService.use('pt-br');
    // this.TotalProducts = 0;
    this.goToHome = false;
    this.user = new User();
    // this.color = "#063";
    this.menuUser = [{}];
    this.menuSuporte = [{}];
    this.menuAdminEmp = [{}];
    this.menuDesenvolvimento = [{}];
    this.menuUserIntegracao = [{}];
  }

  ngOnInit() {
    this.checkMenu();
    setTimeout(() => $(document).foundation());
    var userTemp = JSON.parse(localStorage.getItem("User"));
    this.changePass5 = userTemp.lvlPass;
    this.user.use_id = userTemp.usu_id;
    // this.user.use_sname = FormatTitle.FirstUpper(userTemp.usu_sname);
    this.user.use_semail = userTemp.usu_semail;
    this.user.use_spassword = userTemp.usu_spassword;
    this.user.per_id = userTemp.perId;

    if (this.user.per_id == 1) {
      this.menuUser = [{ descricao: "Boletos Web", submenu: [{ descricao: "Listagem", url: "slips" }] }];
      this.menuToShow = this.menuUser;
    } else if(this.user.per_id == 4){
      this.menuDesenvolvimento = [{ descricao: "Consultas", submenu: [{ descricao: "Listagem", url: "consults" }] }];
      this.menuDesenvolvimento.push({ descricao: "Módulos", submenu: [{ descricao: "Listagem", url: "modules" }] });
      this.menuDesenvolvimento.push({ descricao: "Atualização", submenu: [{ descricao: "Listagem", url: "updates" }] });
      this.menuDesenvolvimento.push({ descricao: "Empresa", submenu: [{ descricao: "Listagem", url: "company-list" }] });
      this.menuToShow = this.menuDesenvolvimento;
    }

  }

  ngAfterViewChecked() {
    this.checkMenu();
    this.cdRef.detectChanges();
  }

    public checkMenu() {
    if (window.location.href.indexOf('/slips') > -1) {
      this.checkMenuSlips = 1;
    } else if (window.location.href.indexOf('/consults') > -1 ||
      window.location.href.indexOf('/consults-cad') > -1) {
      this.checkMenuSlips = 2;
    } else if (window.location.href.indexOf('/modules') > -1 ||
      window.location.href.indexOf('/modules-cad') > -1) {
      this.checkMenuSlips = 3;
    }
  }

  logoff(): void {
    localStorage.removeItem('User');
    this.router.navigate(["/login"]);
  }

  openPage(page) {
    if (page == "admin" || page == "cart" || page == "config" || page == "myrequests") {
      this.goToHome = true;
    } else {
      this.goToHome = false;
    }
    this.router.navigate(['/', page]);
  }

  // private GetInfoUser(): void {
  //   this.serviceClient.SelectClient(this.ClientInfo.code).then((result) => {
  //     if (result != null) {
  //       var recebe = result;
  //       recebe.typeUser = this.ClientInfo.typeUser;
  //       this.userData.User = recebe;
  //     }
  //   });
  // }

  changePass() {
    if (this.changePass5 == false) {
      if (!!this.pass && !!this.newPass && !!this.repeatNewPass) {
        this.changePass2 = false;

        if (!!this.pass && this.newPass == this.repeatNewPass) {
          this.changePass1 = false;

          var teste = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[§'+º´~,.!#$%&/()=?*ª`^|;:_@€£‰¶÷≠]).*$/;
          var teste2 = teste.test(this.newPass);

          if (teste2 != null && teste2 != false) {
            this.changePass4 = false;

            this.user.Pass = this.pass;
            this.user.use_spassword = this.newPass;
            this.CallFunction();

          } else {
            this.changePass4 = true;
          }

        } else {
          this.changePass1 = true;
        }

      } else {
        this.changePass2 = true;
      }
    }
  }

  CallFunction() {
    this.user.Logged = true;
    this.loginService.ChangePassword(this.user).then((result) => {
      if (result) {
        this.changePass3 = false;
        swal.fire({
          title: "Sucesso! :)",
          text: "Suas alterações foram salvas",
          type: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            this.changePass5 = true;
            this.user.lvlPass = true;
            localStorage.setItem('User', JSON.stringify(this.ClientInfo));
          }
        });
      } else if (result == 2) {
        this.changePass3 = true;
      } else {
        swal.fire("Oops! :(", "Infelizmente não foi possível salvar suas alterações no momento, tente novamente mais tarde.", "error");
      }
    });
  }

  // setMenu(menu){
  //   this.homeComponent.selectedModule(menu);
  // }

  PressEnter(event) {
    if (event.keyCode == 13 || event.which == 13) {
      this.changePass();
    }
  }

  goTo(link) {
    this.router.navigate(['/home/' + link]);
  }

}
