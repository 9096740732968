import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule } from 'src/app/app.module';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private baseUrl = AppModule.returnbaseUrl();

  constructor(private http: HttpClient) { }

  public getData(link:string) {
    var url = this.baseUrl + link;
    return this.http.get(url, {})
      .toPromise()
      .then(
        res => { // Success
          return res;
        },
        msg => { // Error
          return msg;
        });
  }
}
